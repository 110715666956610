/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-long-do-rechargeable-hearing-aids-last",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-long-do-rechargeable-hearing-aids-last",
    "aria-label": "how long do rechargeable hearing aids last permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How long do rechargeable hearing aids last?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Modern hearing aids offer incredible features that make life better for those struggling with ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), ". While today’s hearing devices provide more sound clarity than ever before, some may argue that there’s another feature that has truly transformed their lives — rechargeable batteries."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For years, people with hearing loss only had one choice, which involved changing tiny hearing aid batteries every 2-3 days. For some people, that is still their preferred method. But others find changing batteries all the time frustrating for a couple of key reasons. Not only do you have to have a supply of hearing batteries with you at all times, they are sometimes very hard to change, especially for those with dexterity issues."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re considering a rechargeable hearing aid, you may be wondering how long rechargeable hearing aid batteries last. The last thing you want is the battery dying when you need it most."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "lifespan-of-rechargeable-hearing-aid-batteries",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#lifespan-of-rechargeable-hearing-aid-batteries",
    "aria-label": "lifespan of rechargeable hearing aid batteries permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Lifespan of rechargeable hearing aid batteries"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "After charging for just a few hours, most rechargeable hearing aids will give you a full day’s charge — sometimes even longer at up to 30 hours! Note that things like streaming audio and music with the ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth feature"), " takes more battery power and can lessen the charge more quickly throughout the day."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In regard to the total lifespan of rechargeable hearing aids, many last around four or five years. This will vary depending on the model of your hearing aid and amount of daily wear and tear."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-to-look-for-in-a-rechargeable-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-to-look-for-in-a-rechargeable-hearing-aid",
    "aria-label": "what to look for in a rechargeable hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What to look for in a rechargeable hearing aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Be sure to look for a device that uses rechargeable lithium-ion batteries. These are the most popular batteries used in hearing devices, electronics like laptops and cellphones, and even electric cars. They’re known to be faster and more powerful than other batteries."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Also, check to see if your charger is portable. A charger you can take anywhere and even plug into your car is a real game changer and source of comfort when you’re on the go. A quick 30-minute charge can often give you hours of additional wear time, but be sure to check your ", React.createElement(_components.a, {
    href: "/hearing-aids/user-guides/",
    className: "c-md-a"
  }, "user guide"), " for specifics."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "charging-your-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#charging-your-hearing-aids",
    "aria-label": "charging your hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Charging your hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To recharge your hearing aids, you simply set your devices in the hearing aid charging case. Charging your devices overnight while you’re sleeping is ideal. This will ensure they’re fully charged and ready to go the next day. Again, having a portable charger that can go anywhere with you is a great backup just in case."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s also advisable never to leave your hearing aids in an unplugged charger, as this can shorten the total lifespan of the devices. Be sure to always keep your hearing aid charger plugged directly into the wall to ensure the best charge and longest lifespan."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "we-can-answer-your-rechargeable-hearing-aid-questions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#we-can-answer-your-rechargeable-hearing-aid-questions",
    "aria-label": "we can answer your rechargeable hearing aid questions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We can answer your rechargeable hearing aid questions"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We’re here to help. Click below to see if you qualify — once you’re ready — for a 45-day no-risk trial of the newest rechargeable hearing aids. Just have questions? We’ll call you and answer those with no expectation of purchase."), "\n", React.createElement(LandingPageCta, {
    copy: "Check if you Qualify for a No-Risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
